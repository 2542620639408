<!-- 学习目标  教师达成率 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :clearSort="clearSort"
        :isDownload="true"
        @onDownload="onDownload"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onSortChange="sortChange"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchVal: '',
      searchConfig: [
        {
          prop: 'target_exam_id',
          tag: "select",
          placeholder: '筛选考试名称',
          options: [],
          label: "name",
          value: "id"
        },
        {
          prop: 'target_id',
          tag: "select",
          placeholder: '筛选目标任务名称',
          options: [],
          label: "name",
          value: "id"
        },
        {
          prop: 'school_id',
          tag: "select",
          placeholder: '筛选校区',
          options: [],
          label: "school_name",
          value: "id",
        },
        {
          prop: 'grade_id',
          tag: "select",
          placeholder: '筛选年级',
          options: [],
          label: "grade_name",
          value: "id",
        },
        {
          prop: "teacher_name",
          placeholder: "搜索教师姓名",
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      order_by: '',
      clearSort: false,

      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {prop: "teacher_name", label: "教师姓名"},
        {prop: "subject_name", label: "科目"},
        {prop: "student_total_num", label: "带班人数", sortable: 'custom'},
        {prop: "student_exam_num", label: "实考人数", sortable: 'custom'},
        {prop: "student_succ_num", label: "达标人数", sortable: 'custom'},
        {prop: "success_rate", label: "达成率", sortable: 'custom'},
        {prop: "rank_num", label: "达成率排名", sortable: 'custom'},
        {prop: "target_name", label: "目标任务名称"},
        {prop: "target_exam_name", label: "考试名称"},
        {prop: "grade_name", label: "年级"},
        {prop: "school_name", label: "校区"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          buttons: [{ type:"view", text:"详情" }]
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getConfig()
    this.getData()
  },
  activated() {
    this.getConfig()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getConfig() {
      this.$_axios2('api/study-target-statistic/target-exam').then(res => {
        this.searchConfig[0].options = res.data.data || []
      })
      this.$_axios2('api/study-target-statistic/target').then(res => {
        this.searchConfig[1].options = res.data.data || []
      })
      this.$_axios2('api/study-target-statistic/school').then(res => {
        this.searchConfig[2].options = res.data.data || []
      })
      this.$_axios2('api/study-target-statistic/grade').then(res => {
        this.searchConfig[3].options = res.data.data || []
      })
    },

    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      let order_by = this.order_by
      if (order_by) params.order_by = order_by
      this.tableData = [];
      this.$_axios2('api/study-target-statistic/teacher', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
        this.searchVal = JSON.parse(JSON.stringify(this.search))
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.order_by = ''
      this.clearSort = true
      this.getData(val)
    },

    handleEdit(row, text) {
      this.$router.push('./student?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    onDownload() {
      let params = {...this.searchVal, page: this.page}
      let order_by = this.order_by
      if (order_by) params.order_by = order_by
      this.$_axios2('api/study-target-statistic/teacher-export', {params}).then(res => {
        if(typeof res.data === 'string') tools.download('教学老师目标达成率', res.data)
      })
    },

    sortChange({column, prop, order}) {
      let str;
      str = order ? (order === 'ascending' ? (prop + ' desc') : (prop + ' asc')) : null;
      this.order_by = str
      this.clearSort = false
      this.getData()
    }
  },
}
</script>

<style lang="scss" scoped>

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
